import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EventService } from '@werfy/core';

@Component({
  selector: 'wp-root',
  standalone: true,
  imports: [RouterOutlet],
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(eventService: EventService) {
    eventService.initialize();
  }
}
